import { Swipe, SwipeItem,Button,Icon,Image
        as VanImage,Field,Search,GoodsAction,
    GoodsActionIcon, GoodsActionButton,
    Popup,Tabbar, TabbarItem, Tab,
    Tabs,Form,RadioGroup, Radio ,Rate, List,Dialog,
    Popover,Collapse, CollapseItem,Overlay,Divider,Empty,Toast } from 'vant';
// 放入数组中
let plugins=[
    Swipe,SwipeItem,Button,Icon,VanImage,Field,Search,
    GoodsAction, GoodsActionIcon, GoodsActionButton,
    Popup,Tabbar, TabbarItem,Tab, Tabs,Form,RadioGroup, Radio,Rate,List,Dialog,Popover,
    Collapse, CollapseItem,Overlay,Divider,Empty,Toast
]
export default function getVant(Vue){
    plugins.forEach((item)=>{
        return Vue.use(item)
    })
}
