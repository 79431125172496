<template>
<!--  手机端-->
  <div v-if="isMobile">
    <el-container>
      <el-header>
        <div style="display: flex">
          <div style="display: flex">
            <div style="margin-top: 10px"><img src="../assets/logo-title.png" style="width:50px;height:50px"></div>
            <div style="flex: 1;width: 100%;margin-left: 10px">
              <span style="color:black;font-weight:bold;font-size:30px">个人介绍</span>
              <div style="position: absolute;top:30px">
                <span style="color:black;font-size: 10px">PERSONAL INTRODUCTION</span>
              </div>
            </div>
          </div>
          <div @click="goShow" style="flex:1;text-align: right;font-size: 33px;margin-top: 10px">
            <i  class="el-icon-s-fold"></i>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </div>
  <!--  电脑端-->
  <div v-else>
    <el-container>
      <el-header>
        <div style="display: flex">
          <div style="display: flex">
            <div style="margin-top: 5px"><img src="../assets/logo-title.png" style="width:55px;height:55px"></div>
            <div style="flex: 1;margin-left: 10px;"><span style="color:black;font-weight:bold;font-size:25px">个人介绍</span>
            </div>
          </div>
          <div style="margin-left: 60px;margin-top: 5px">
            <el-menu class="el-menu-demo" mode="horizontal" router>
              <el-menu-item index="home">首页</el-menu-item>
              <el-menu-item index="about">关于我们</el-menu-item>
              <el-menu-item index="work">产品服务</el-menu-item>
              <el-menu-item index="join">加入我们</el-menu-item>
              <el-menu-item index="message">在线留言</el-menu-item>
<!--              <el-menu-item index="login">公众号登录</el-menu-item>-->
              <el-menu-item index="chat">智能人</el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'LayOutFront',
  data() {
    return {
      isMobile: false,
      show: false
    }
  },
  created() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  mounted() {

  },
  methods: {
    goShow() {
      if (this.$route.path === '/join') {
        return;
      } else {
        this.$router.push('/join');
      }
    },
  }
}
</script>

<style>
.el-menu.el-menu--horizontal{
  border-bottom: none !important;
}
.el-menu-item{
  width: 150px;
  color: #17233d !important;
  font-weight: bold;
  font-size: 16px !important;
}
.el-header{
  background-color: white;
  color: #17233d;
  text-align: center;
  line-height: 60px;
}
.el-main {
  background-color: white;
  color: #333;
  text-align: center;
}
.el-menu{
  background-color: white !important;
  color: #17233d !important;
}
</style>
