import Vue from 'vue'
import VueRouter from 'vue-router'
import LayOutFront from '../views/LayOutFront'
import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
    {
        path: '/chat',
        name: 'Chat',
        component: () => import('@/views/chat/Chat')
    },
    {
        path: '/',
        name: 'home',
        component: LayOutFront,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('@/views/home/Home')
            },
            {
                path: 'about',
                name: 'About',
                component: () => import('@/views/about/About')
            },
            {
                path: 'work',
                name: 'Work',
                component: () => import('@/views/work/Work')
            },
            {
                path: 'join',
                name: 'Join',
                component: () => import('@/views/join/Join')
            },
            {
                path: 'message',
                name: 'Message',
                component: () => import('@/views/message/Message')
            },
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/login/Login')
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    // base:'/yy/',
    base: process.env.BASE_URL,
    routes
})
export default router
